import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { useParams } from 'react-router-dom';

const ProtectedRoute = ({ allowedRoles}) => {
  const { findRoleId  } = useAuth();
  const { clinicId } = useParams();

  console.log("clinicic is ",clinicId)
  var rolesobj;
  const roles=localStorage.getItem('roles')
  if(roles){
    rolesobj=JSON.parse(roles)
  }
  //const selectedClinicId=localStorage.getItem('selectedClinicId')
  if (!roles) {
    return <Navigate to="/login" replace />;
  }
  //console.log("allowed roles",allowedRoles)
  //console.log("rolesobj",rolesobj)
  //console.log("selectedclinicid",clinicId)
  const hasAllowedRole = findRoleId(rolesobj,clinicId,allowedRoles[0])
  if(hasAllowedRole){
    localStorage.setItem('selectedClinicId',clinicId)
  }
  if (!hasAllowedRole) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;