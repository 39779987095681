import React from 'react';
import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
    const navigator=useNavigate();
  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      maxWidth: '400px',
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    infoText: {
      fontSize: '14px',
      color: '#666',
      marginBottom: '20px',
    },
    button: {
      backgroundColor: '#7C5CFF',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      padding: '10px 15px',
      fontSize: '14px',
      cursor: 'pointer',
      width: '100%',
      marginBottom: '15px',
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Unauthorized Access</h1>
      <p style={styles.infoText}>You don't have permission to view this page.</p>
      <button onClick={()=>{navigator("/")}} style={styles.button} >Go back home</button>
    </div>
  );
};

export default Unauthorized;