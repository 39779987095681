import React, { createContext, useContext, useState, useEffect,useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [currentClinic, setCurrentClinic] = useState(null);
  const [currentRole, setCurrentRole] = useState(null);
  const [appState, setAppState] = useState('initial');
  const [credentialid, setCredentialid] = useState(null);
  const [loading, setloading] = useState(false);
  const [loginerror, setloginerror] = useState();
  const [reloadRoute, setreloadRoute] = useState(true);
  const appStateRef = useRef('initial');
  const userRef=useRef(null);
  const navigator = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
     // console.log("token found")
      try {
       // verifyToken(storedToken);
        const decodedToken = jwtDecode(storedToken);
        const roles= extractRoles(decodedToken);
        setUser(roles);
        userRef.current=roles;
        setToken(storedToken);
        
        const storedClinicId = localStorage.getItem('selectedClinicId');
        const storedRoleId = localStorage.getItem('selectedRoleId');
        if (storedClinicId && storedRoleId) {
          //console.log("selecting clinic")
          selectClinic(parseInt(storedClinicId, 10), parseInt(storedRoleId, 10));
        }
      } catch (error) {
        console.error('Invalid token:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('selectedClinicId');
        localStorage.removeItem('selectedRoleId');
      }
    }
  }, []);

  //verify token with the api to check if it is valid
  const VerifyToken = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/credentials/token`, {
        method: 'GET', 
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'}

      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  };

  const getVerifiedUser = async () => {
    if(user){
      return user;
    }
    //get token from local storage
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      //verify token with the api to check if it is valid
      try{
        await VerifyToken(storedToken);
      }
      catch(error){
        console.error('Token verification failed:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('selectedClinicId');
        localStorage.removeItem('selectedRoleId');
        throw error;
      }
      const decodedToken = jwtDecode(storedToken);
      const roles= extractRoles(decodedToken);
      setUser(roles);
      userRef.current=roles;
      setToken(storedToken);
      return roles;
    } 

    return null;
  }



  /*const login = async (credentials) => {
    try {
      // Implement your API call here
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();
      const { token } = data;

      localStorage.setItem('token', token);
      const decodedToken = jwt_decode(token);
      setUser(decodedToken.user);
      setToken(token);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };*/

const login = async (credentials) => {
      // requestoptions object containing the username and password to be sent to the backend server using the Fetch API
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        //converts js object in json string
        //The username and password are taken from the loginform state.
        username: credentials.username,
        password: credentials.password,
      }),
    }; //the username and password is send to backend via api

    /* fetch function is used to make a POST request to the backend API with the provided 
        requestOptions. It sends the username and password to the server for authentication.
    */
   setloading(true)
    fetch(`${process.env.REACT_APP_ENDPOINT}/credentials/login`, requestOptions)
      .then((response) => {
        //setprogress(50);
        return response.json();
      })
      .then((data) => {
        //Check if the response data is present
        if (data) {
          /*Check if the response data is success; The api returns an object like 
          {
              "jwt": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBY2Nlc3NUb2tlbiIsImp0aSI6IjViMDAxZjQ4LTlkMTItNDgzYi1hMjQxLWRhMTcwOTQyNGM4MCIsImlhdCI6IjcvMjQvMjAyMyA0OjQ2OjIxIEFNIiwiSWQiOiIyIiwidXNlcm5hbWUiOiJBZG1pbnN0cmF0b3IiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBZG1pbiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJBZG1pbiIsImV4cCI6MTY5MDI2MDM4MSwiaXNzIjoiQXV0aGVudGljYXRpb25TZXJ2ZXIiLCJhdWQiOiJDbGllbnQifQ.0qEqkeKBXWVrH_gGSOaNhuHeag8rn23J78aVX2ao91U",
              "usergroup": "Admin",
              "id": 2,
              "resp": "success"
          }*/

          // Check if data.response is success which means the username and password are correct.
          if (data.resp === "success") {
            //Set JWT token in APP.js  to token via tookhandler
            setToken(data.jwt);
            localStorage.clear();
            localStorage.setItem("token", data.jwt);
            var decoded = jwtDecode(data.jwt);
            console.log("decoded",decoded)
            //var roles= extractRoles(decoded);
            var roles=extractRoles(decoded);
            //console.log("rolesNew",roles)
            if(data.usergroup==="Super_Admin"){
              localStorage.setItem("usergroup", data.usergroup);
              navigator("/super_admin")
            }else{
              setUser(roles)
            userRef.current=roles;
            }
           
            setCredentialid(data.id);
            //console.log(decoded);
            localStorage.clear();
            localStorage.setItem("roles",JSON.stringify(roles))
            localStorage.setItem("token", data.jwt);
            localStorage.setItem("usergroup", data.usergroup);
            localStorage.setItem("credentialid", data.id);
            localStorage.setItem("clinics", data.clinics);
            setloading(false)
        
          } else throw new Error(data.resp);
        }
      })
      .catch((error) => {
        console.log("Error in login:", error.message);
        setloading(false)
        setloginerror(error.message)
      });
  }


  function extractRoles(inputObject) {
   
      const result = {};
  
      for (const key in inputObject) {
          if (key.startsWith("Role_")) {
              const parts = key.split('_');
              if (parts.length >= 6) {
                  const role = parts[1];
                  const clinicId = parts[2];
                  const clinicName = parts[3];
                  const departmentId = parts[4];
                  const departmentName = parts[5];
                  const position = parts[6];
                  const roleId = inputObject[key].replace(/"/g, '');
  
                  // Initialize the clinic entry if it doesn't exist
                  if (!result[clinicId]) {
                      result[clinicId] = {
                          clinicName: clinicName,
                          id: clinicId,
                          departments: [],
                          roles: []
                      };
                  }
  
                  // Add or update the department
                  let department = result[clinicId].departments.find(d => d.id === departmentId);
                  if (!department) {
                      department = { id: departmentId, name: departmentName, roles: [] };
                      result[clinicId].departments.push(department);
                  }
                  if (!department.roles.includes(roleId)) {
                      department.roles.push(roleId);
                  }
  
                  // Add the role if it doesn't exist
                  if (!result[clinicId].roles.some(r => r.id === roleId)) {
                      result[clinicId].roles.push({ id: roleId, role: role, position: position });
                  }
              }
          }
      }
  
      // Convert the result object to an array without sorting
      return Object.values(result);
  }
  

  function findRoleId(clinics, clinicId, roleName) {
    // Loop through the clinics array
    for (let clinic of clinics) {
        // Check if clinic ID matches
        if (clinic.id === clinicId) {
            // Loop through the roles in the clinic
            for (let role of clinic.roles) {
                // Check if role name matches
                if (role.role.toLowerCase() === roleName.toLowerCase()) {
                    return role.id;  // Return role ID if found
                }
            }
        }
    }
    // Return null if clinic or role is not found
    return null;
}

  const logout = () => {
    localStorage.clear();
    setUser(null);
    setToken(null);
    setCurrentClinic(null);
    setCurrentRole(null);
   userRef.current=null;
    navigator("/login")
  };

  const selectClinic = (clinicId, roleId) => {
    if (!userRef.current) return;
    
    const selectedClinic = userRef.current.find(clinic => parseInt(clinic.id) === parseInt(clinicId));
    
    if (selectedClinic) {
        const selectedRole = selectedClinic.roles.find(role => parseInt(role.id) === parseInt(roleId));
        
        if (selectedRole) {
            setCurrentClinic(selectedClinic);
            setCurrentRole(selectedRole);
            localStorage.setItem('selectedClinicId', clinicId.toString());
            localStorage.setItem('selectedRoleId', roleId.toString());
            
            fetch(`${process.env.REACT_APP_ENDPOINT}/clinic/full/${clinicId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any authorization headers if required
                    // 'Authorization': 'Bearer ' + yourAuthToken
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(fullClinicData => {
                localStorage.setItem('fullClinicData', JSON.stringify(fullClinicData));
                setreloadRoute(!reloadRoute);
            })
            .catch(error => {
                console.error('Error fetching full clinic data:', error);
                // You might want to show a user-friendly error message here
            });
            
        } else {
            console.error('Selected role not found');
        }
    } else {
        console.error('Selected clinic not found');
    }
};

  const hasAccess = (clinicId, role) => {
    if (!currentClinic || !currentRole) return false;
    return currentClinic.id === clinicId && currentRole.role.toLowerCase() === role.toLowerCase();
  };

  const updateAppState = (state) => {
    appStateRef.current = state;
    setAppState(state);
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      token, 
      login, 
      logout, 
      selectClinic, 
      hasAccess, 
      loginerror,
      currentClinic, 
      setCurrentClinic,
      currentRole,
      updateAppState,
      appStateRef,
      appState,
      credentialid,
      loading,
      extractRoles,
      findRoleId,
      getVerifiedUser,
      reloadRoute
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};