import React, { Suspense } from "react";
import { useState, useEffect } from "react";
import styles from "../Splash.module.css";
import Loader from "../Loader";
import pills from "../pills.svg";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import wellpas_logo from "../wellpas_logo.png";
import LoginHelp from "./LoginHelp";
import { useNavigate } from "react-router-dom";
import TextfieldStyled from "../components/TextfieldStyled/TextfieldStyled";
import { Error } from "@material-ui/icons";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../utils/AuthProvider";
import { useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { LocalHospital } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

// Sub-components (these could be moved to separate files for better organization)
const LoginForm = ({ onSubmit, loading, loginerror, logout }) => {
  useEffect(() => {
    console.log("login form re-loaded");
  }, []);

  const [loginform, setloginform] = useState({
    username: "",
    password: "",
  });

  const [loginFormState, setloginFormState] = useState(
    {
      username: {
        error: false,
        helpertext: "",
      },
      password: {
        error: false,
        helpertext: "",
      },
    }
  );

  const navigate = useNavigate();

  const handlesubmit = async (e) => {
    //reset loginform state
    setloginFormState({
      username: {
        error: false,
        helpertext: "",
      },
      password: {
        error: false,
        helpertext: "",
      },  
    });

    //check if the username and password fields are empty
    if (loginform.username === "") {
      setloginFormState((prev) => ({
        ...prev,
        username: {
          error: true,
          helpertext: "Username is required",
        },
      }));
    }

    if (loginform.password === "") {
      setloginFormState((prev) => ({
        ...prev,
        password: {
          error: true,
          helpertext: "Password is required",
        },
      }));
    }


    e.preventDefault();
    await onSubmit(loginform);
  };

  //handlechange: event handler for the input fields in the login form
  const handlechange = (e) => {
    console.log("handleChange Called");
    let kew = { ...loginform }; //creates a copy of the loginform object
    //updates the input fields based on the event.target.name,
    kew[e.target.name] = e.target.value;
    // then updates the loginform state using setloginform
    setloginform(kew);
  };

  useEffect(() => {
    if (loginerror) {
      if(loginerror.includes("User")){
      setloginFormState((prev) => ({
        ...prev,
        username: {
          error: true,
          helpertext: loginerror,
        },
      }));
    }
  
    if(loginerror.includes("password")){
      setloginFormState((prev) => ({
        ...prev,
        password: {
          error: true,
          helpertext: loginerror,
        },
      }));
    }}
    
  }, [loginerror]);

  return (
    <div className={styles.major}>
      <img
        style={{
          width: "130px",
          padding: "20px",
          paddingTop: "15px",
        }}
        src={wellpas_logo}
        alt="tabeel_logo"
      />
      <div
        style={{
          width: "100%",
          height: "0.2px",
          backgroundColor: "#c0b7fa",
          position: "relative",
        }}
      >
        <img
          src={pills}
          alt="pills"
          style={{
            height: "120px",
            bottom: "0",
            right: "30px",
            position: "absolute",
          }}
        />
      </div>
      <form className={styles.formic}>
        <div className={styles.fieldContainer}>
          <TextfieldStyled
            placeholder="username"
            name="username"
            width="100%"
            color="white"
            label=""
            onChangehandler={handlechange}
            value={loginform.username}
            error={loginFormState.username.error}
            helpertext={loginFormState.username.helpertext}
            id="username"
          />
        </div>

        <div className={styles.fieldContainer}>
          <TextfieldStyled
            placeholder="password"
            type="password"
            name="password"
            width="100%"
            color="white"
            label=""
            onChangehandler={handlechange}
            value={loginform.password}
            error={loginFormState.password.error}
            helpertext={loginFormState.password.helpertext}
            id="password"
          />
        </div>
      </form>

      {false && (
        <div className={styles.error}>
          <Error fontSize="small" />
          {loginerror}
        </div>
      )}

      {/* This div contains the login button and a loading indicator. It uses inline styles for layout and spacing. */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        {/* This button is used for logging in. When clicked, it calls the handlesubmit function */}
        <Button
          name="login"
          fullWidth
          variant="outlined"
          style={{
            textTransform: "none",
            boxSizing: "border-box",
            color: "#6153BD",
            borderColor: "#6153BD",
            fontWeight: "300",
          }}
          onClick={handlesubmit}
        >
          {/* button text changes based on the loading state */}
          {/* If loading is false, it shows "Login"; otherwise, it shows -> Signing in... 
  with a spinning loader. */}
          {!loading ? (
            "LOGIN"
          ) : (
            <>
              Signing in ...{" "}
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  marginLeft: "20px",
                }}
              >
                <Loader color="#6153BD" />
                {/*loader will shown inside button */}
              </div>
            </>
          )}
        </Button>
        {/* This div contains a message and a button for requesting login assistance. */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "grey",
          }}
        >
          <Typography style={{ fontWeight: "200" }} variant="body2">
            Trouble signing in?
          </Typography>
          {/* This button is used for login assistance. When clicked, it sets the 
    dialogVisibility state to true and dialogType to "login" */}
          <Button
            onClick={() => {
              navigate("/login/forgot-password");
            }}
            style={{
              textTransform: "none",
              fontWeight: "400",
              color: "#6153BD",
            }}
            variant="text"
            size="small"
          >
            CLICK HERE
          </Button>
        </div>
      </div>
    </div>
  );
};

const ClinicSelect = ({ onSelect, logout }) => {
  const { user } = useAuth();
  //console.log(user)
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "60%",
        alignItems: "flex-start",
        gap:"10px",
        
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          fontWeight: "300",
         
          fontSize: "16px",
          padding: "10px",
          paddingLeft: "20px",
          color: "#6153BD",
        }}
      >
       
        <ButtonBase
          style={{
            display: "flex",
            alignItems: "center",
            color: "#6153BD",
            fontSize: "15px",
          }}
          onClick={() => {
            logout();
          }}
        >
          <ArrowBack style={{ color: "#6153BD" }} />
        </ButtonBase>
    
        
        Select Clinic
      </div>
    
      {user.map((clinic) => (
        <>
          <ButtonBase
            name="login"
            fullWidth
            variant="outlined"
            style={{width:"100%",border:"0.2px solid #c0b7fa"}}
           
            onClick={() => onSelect(clinic)}
          >
            <div  style={{
              display: "flex",
              justifyContent: "flex-start",
              boxSizing: "border-box",
              alignItems: "center",
              color: "#6153BD",
              fontWeight: "200",
              paddingLeft: "20px",
              width: "100%",
              fontSize: "18px",
              gap:"5px"
            }}>
              <LocalHospital style={{ color: "#6153BD" }} />
              <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",padding:"10px"}} >

               <div>{clinic.clinicName}</div>
               <div style={{color:"gray",fontSize:"14px"}}>{"KP road Anantnag, 192101"}</div>

               </div>
               
            </div>
           
          </ButtonBase>
         
        </>
      ))}
     
    </div>
  );
};

const RoleSelect = ({ onSelect, logout }) => {
  const { currentClinic } = useAuth();
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "60%",
        alignItems: "flex-start",
        gap: "10px"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          fontWeight: "300",
          fontSize: "16px",
          padding: "10px",
          paddingLeft: "20px",
          color: "#6153BD",
        }}
      >
        <ButtonBase
          style={{
            display: "flex",
            alignItems: "center",
            color: "#6153BD",
            fontSize: "15px",
          }}
          onClick={() => {
            navigate("/clinics");
          }}
        >
          <ArrowBack style={{ color: "#6153BD" }} />
        </ButtonBase>
        Select Role
      </div>

      {currentClinic && currentClinic.roles.map((role) => (
        <ButtonBase
          key={role.id}
          name="role"
          fullWidth
          variant="outlined"
          style={{ width: "100%", border: "0.2px solid #c0b7fa" }}
          onClick={() => onSelect(role)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              boxSizing: "border-box",
              alignItems: "center",
              color: "#6153BD",
              fontWeight: "200",
              paddingLeft: "20px",
              width: "100%",
              fontSize: "18px",
              gap: "5px"
            }}
          >
            <LocalHospital style={{ color: "#6153BD" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "10px"
              }}
            >
              <div>{`${role.position}`}</div>
              <div style={{ color: "gray", fontSize: "14px" }}>
                {role.role.toUpperCase()}
              </div>
            </div>
          </div>
        </ButtonBase>
      ))}
    </div>
  );
};

const ForgotPassword = () => {
  // Implement your forgot password form here
  return <LoginHelp />;
};

// Loading component for Suspense fallback
const Loading = () => <div>Loading...</div>;

const LoginMain = () => {
  const { login, user, selectClinic, loading, loginerror, logout } = useAuth();
  const navigate = useNavigate();
  const [selectedClinic, setSelectedClinic] = useState(null);

  useEffect(() => {
    //console.log("loginmain re-loaded")
  }, []);
  const handleLogin = async (credentials) => {
    await login(credentials);
    //navigate('/clinics');
  };

  const handleClinicSelect = (clinic) => {
    setSelectedClinic(clinic);
    navigate(`/clinics/${clinic.id}/roles`);
  };

  const handleRoleSelect = (role) => {
    selectClinic(parseInt(selectedClinic.id), parseInt(role.id));
    navigate(`/`);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column",width:"100%",alignItems:"center"}}>
      
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path="/login"
            element={
              user ? (
                <Navigate to="/clinics" />
              ) : (
                <LoginForm
                  onSubmit={handleLogin}
                  loading={loading}
                  loginerror={loginerror}
                />
              )
            }
          />
          <Route
            path="/clinics"
            element={
              user ? (
                <ClinicSelect onSelect={handleClinicSelect} logout={logout} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route element={<ProtectedClinicRoute />}>
            <Route
              path="/clinics/:clinicId/roles"
              element={
                <RoleSelect
                  selectedClinic={selectedClinic}
                  onSelect={handleRoleSelect}
                  logout={logout}
                />
              }
            />
          </Route>
          <Route element={<ProtectedClinicRoleRoute />}>
            <Route
              path="/clinics/:clinicId/roles"
              element={
                <RoleSelect
                  selectedClinic={selectedClinic}
                  onSelect={handleRoleSelect}
                  logout={logout}
                />
              }
            />
          </Route>

          <Route path="/login/forgot-password" element={<ForgotPassword />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Suspense>
    </div>
  );
};

// New components to handle params and protection

const ProtectedClinicRoute = () => {
  const { clinicId } = useParams();
  const { getVerifiedUser, setCurrentClinic } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const user = await getVerifiedUser();
        //console.log("user is",user)
        if (!user) {
          setIsAuthorized(false);
        } else {
          const selectedClinic = user.find(
            (clinic) => parseInt(clinic.id) === parseInt(clinicId)
          );
          if (selectedClinic) {
            setCurrentClinic(selectedClinic);
          }
          setIsAuthorized(!!selectedClinic);
        }
      } catch (e) {
        setIsAuthorized(false);
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    verifyUser();
  }, [getVerifiedUser, clinicId]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading component
  }

  return isAuthorized ? <Outlet /> : <Navigate to="/login" />;
};

const ProtectedClinicRoleRoute = () => {
  const { clinicId, roleId, selectClinic } = useParams();
  const { getVerifiedUser, setCurrentClinic } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const user = await getVerifiedUser();
        //console.log("user is",user)
        if (!user) {
          setIsAuthorized(false);
        } else {
          const selectedClinic = user.find(
            (clinic) => parseInt(clinic.id) === parseInt(clinicId)
          );
          if (selectedClinic) {
            setCurrentClinic(selectedClinic);
          }
          setIsAuthorized(!!selectedClinic);
        }
      } catch (e) {
        setIsAuthorized(false);
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    verifyUser();
  }, [getVerifiedUser, clinicId]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading component
  }

  return isAuthorized ? <Outlet /> : <Navigate to="/login" />;
};

export default LoginMain;
