import "./App.css";
import { EnvironmentProvider } from "./utils/EnvironmentProvider";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import AppRoutes from "./AppRoutes";
import { AuthProvider } from "./utils/AuthProvider";


function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1976D2", // Change this to your desired primary color
      },
      amber: {
        main: "#ED6C02", // Change this to your desired primary color
      },
    },
  });

  return (
    <EnvironmentProvider>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </ThemeProvider>
    </EnvironmentProvider>
    /*
      ThemeProvider: The entire app is wrapped inside ThemeProvider to provide the Material-UI
       theme to all components.
    */
  );
}

export default App;
